export function HotelLogo({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108.99 96.85"
      className={className}
      fill={fillColor}
    >
      <g>
        <g>
          <path d="M30.8,22.17c.73,0,.97,.52,.97,1.03,0,2.02-1.86,4.09-5.38,5.39,.56-3.24,2.96-6.42,4.41-6.42M4.13,31.06c-.6,0-1.08-.33-1.08-.92,0-.64,.31-1.38,.64-2.22l3.44-8.76h6.21s-.82,2.12-1.17,2.98c-1.75,4.39-6.05,8.92-8.04,8.92ZM24.9,1.19c.59,0,.92,.4,.92,1.14,0,3.79-5.76,11.77-8.88,14.1,2.97-7.42,5.73-15.24,7.96-15.24ZM12.78,26.93l3.06-7.77h18.27l.39-.95H16.32c5.63-3.9,10.79-11.44,10.79-15.9,0-1.3-.65-2.31-2.12-2.31-4.28,0-7.94,9.11-11.32,18.21H7.48l2.37-5.99h-2.51l-2.35,5.99H.38l-.38,.95H4.61l-2.56,6.54c-.94,2.39-1.52,3.63-1.52,4.76,0,1.22,.73,2.09,2.42,2.09,3.14,0,5.85-3.47,7.04-4.92h.05l-1.82,4.58h2.38s.23-.38,.37-.61c2.86-4.78,5.74-8.89,7.63-8.89,.44,0,.66,.3,.66,.67,0,.61-.39,1.33-.82,2.22-.72,1.47-1.69,3.32-1.69,4.72,0,1.49,1.05,2.23,2.71,2.23,2.36,0,4.14-1.68,4.77-2.22,.15,.7,.84,2.22,3.32,2.22s4.3-1.73,4.86-2.36l-.43-.44c-.51,.48-1.83,1.84-3.66,1.84-1.54,0-2.02-1.13-1.94-2.26,3.92-1.05,7.3-2.96,7.3-5.86,0-.93-.68-2.05-2.52-2.05-2.92,0-6.95,4.03-7.06,8.08-.55,.49-2.04,1.85-3.77,1.85-.88,0-1.24-.51-1.24-1.11,0-1,.99-2.79,1.73-4.49,.45-1.02,.81-1.78,.81-2.63,0-.95-.86-1.72-2.23-1.72-2.56,0-4.87,2.98-6.58,5.51h-.05Z" />
          <path d="M5.13,61.2s-.11,0,1.32,0c4.23,0,5.03-2.61,5.03-4.23s-.76-4.31-5.03-4.31h-1.32v8.55m0-11.35h1.44c2.54,0,4.13-1.65,4.13-4.09,0-2.21-1.34-4.04-4.13-4.04h-1.44v8.14Zm6.19,1.13c.67,.23,3.95,1.64,3.95,6.31,0,3.79-2.98,6.94-8.51,6.94H1.47v-25.48H7.23c5.28,0,7.17,3.38,7.17,6.71,0,3.64-2.26,5.06-3.09,5.42v.1Z" />
          <polyline points="20.1 38.76 29.65 38.76 29.65 41.73 23.77 41.73 23.77 49.67 29.43 49.67 29.43 52.65 23.77 52.65 23.77 61.26 29.81 61.26 29.81 64.23 20.1 64.23 20.1 38.76" />
          <polyline points="51.61 38.76 61.27 38.76 61.27 41.73 55.28 41.73 55.28 49.67 61.05 49.67 61.05 52.65 55.28 52.65 55.28 61.26 61.43 61.26 61.43 64.23 51.61 64.23 51.61 38.76" />
          <polyline points="40.56 57.94 44.27 38.76 47.86 38.76 42.45 64.23 38.25 64.23 32.91 38.76 36.7 38.76 40.42 57.94 40.56 57.94" />
          <polyline points="32.1 71.08 35.77 71.08 35.77 93.58 42.28 93.58 42.28 96.55 32.1 96.55 32.1 71.08" />
          <polyline points="84.78 38.76 88.46 38.76 88.46 61.26 94.94 61.26 94.94 64.23 84.78 64.23 84.78 38.76" />
          <polyline points="101.29 48.56 105.4 38.76 108.99 38.76 102.88 52.82 102.88 64.23 99.21 64.23 99.21 52.82 93.55 38.76 97.42 38.76 101.22 48.56 101.29 48.56" />
          <polyline points="1.47 71.08 5.14 71.08 5.14 82.03 10.9 82.03 10.9 71.08 14.57 71.08 14.57 96.55 10.9 96.55 10.9 85.01 5.14 85.01 5.14 96.55 1.47 96.55 1.47 71.08" />
          <polyline points="21.72 71.08 25.39 71.08 25.39 96.55 21.72 96.55 21.72 71.08" />
          <polyline points="41.33 71.08 55.04 71.08 55.04 73.93 50.02 73.93 50.02 96.55 46.35 96.55 46.35 73.93 41.33 73.93 41.33 71.08" />
          <path d="M68.09,83.79c0-6.56-1.17-10.11-4.02-10.11s-4.02,3.55-4.02,10.11,1.16,10.11,4.02,10.11,4.02-3.55,4.02-10.11m3.89,0c0,7.26-2.55,13.05-7.91,13.05s-7.92-5.79-7.92-13.05,2.5-13.06,7.92-13.06,7.91,5.79,7.91,13.06Z" />
          <path d="M70.38,51.69c.93,0,.26,0,.55,0,3.23,0,4.96-1.93,4.96-5.02,0-2.8-1.64-4.99-5.17-4.99h-.33v10m-3.67-12.93h4.61c5.52,0,8.25,3.51,8.25,7.82,0,5.74-4.09,7.27-4.58,7.49l5.65,10.17h-4.3l-5.07-9.57h-.89v9.57h-3.67v-25.48Z" />
          <polyline points="86.47 87.57 86.47 71.08 89.94 71.08 89.94 96.55 86.08 96.55 81.84 82.73 80.65 78.23 80.58 78.23 80.58 96.55 77.09 96.55 77.09 71.08 81.61 71.08 85.15 82.97 86.35 87.57 86.47 87.57" />
          <path d="M93.4,70.94c.97,0,1.8,.75,1.8,1.78s-.83,1.79-1.8,1.79-1.81-.75-1.81-1.79,.83-1.78,1.81-1.78m0,.29c-.83,0-1.46,.64-1.46,1.49s.64,1.5,1.46,1.5,1.46-.63,1.46-1.5-.64-1.49-1.46-1.49Zm-.38,2.52h-.32v-2.06h.79c.49,0,.73,.18,.73,.59,0,.37-.23,.53-.54,.57l.59,.9h-.35l-.55-.89h-.35v.89Zm0-1.15h.37c.38,0,.51-.11,.51-.34,0-.2-.1-.31-.46-.31h-.43v.64Z" />
        </g>
      </g>
    </svg>
  );
}
